<template>
  <div ref="map" class="map">
    <v-snackbar bottom right :color="snackbar.color" v-model="snackbar.value">
      {{ snackbar.message }}
      <template v-slot:action>
        <v-btn color="white" text @click="snackbar.value = false" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <div class="orientation d-flex justify-center">
      <v-btn
        v-clipboard:copy="orientation"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
        class="mt-2"
        :outlined="!$store.state.orientation"
        :color="$store.state.orientation ? 'primary' : 'orange'"
        v-show="orientation"
      >
        {{ orientation }}
      </v-btn>
    </div>
    <div class="mapboxgl-control-container">
      <div class="mapboxgl-ctrl-top-left">
        <div>
          <div class="mapboxgl-ctrl mapboxgl-ctrl-group">
            <v-menu
              offset-x
              :close-on-content-click="false"
              v-model="dialog.layer"
              transition="slide-x-transition"
            >
              <template #activator="{ on }">
                <button class="mapboxgl-ctrl-fullscreen" v-on="on">
                  <v-icon size="20" style="margin-top: -3px" color="black">mdi-layers</v-icon>
                </button>
              </template>
              <map-setting :show="mapSettingFields" :linkedGroups="linkedGroupsLabels">
                <template #title>
                  Layers
                  <v-spacer></v-spacer>
                  <v-btn @click="dialog.layer = false" icon>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
              </map-setting>
            </v-menu>
            <select-map-layer v-model="mapStyle" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import center from '@turf/center';
import {
  isSatellite,
  labelToGeostyle,
  MAP_STYLES,
  parcelGeoJSON,
  parseGeoJSON
} from '../../utils/map';
import Map from '../../services/map';
import config from '../../config';
import {
  geostyleParcel,
  geostyleParcelPoint,
  geostyleParcelSatellite
} from '../../utils/geostyles';
import { featureCollection, point } from '@turf/helpers';
import { throttle } from 'throttle-debounce';
import combine from '@turf/combine';
import bbox from '@turf/bbox';
import Orientation from '../../services/orientation';

import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';
import { fmtParcelNumber, getNearbyParcels } from '../../utils/parcel';
import { CMS } from '../../services/cms';
import LinkedGroup from '../../services/linkedGroup';
import Parcel from '../../services/parcel';

VueClipboard.config.autoSetContainer = true;

Vue.use(VueClipboard);

export default {
  components: {
    SelectMapLayer: () => import('../SelectMapLayer.vue'),
    MapSetting: () => import('./MapSettings.vue'),
  },
  props: {
    parcel: {
      type: [String, Object],
      default: null
    },
    parcelNumber: {
      type: String,
      required: true
    },
    parcelId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      snackbar: {
        color: 'primary',
        value: false,
        message: ''
      },
      map: null,
      style: MAP_STYLES.outdoors,
      orientation: '',
      orientationObj: null,
      data: null,
      dialog: {
        layer: false
      },
      mapSettingFields: [
        'reference',
        'tct',
        'tot',
        'acres',
        'scale',
        'boundary',
        'parcelId',
        'monitoringColor'
      ],
      nearby: {
        tct: [],
        tot: []
      }
    };
  },
  watch: {
    '$store.state.parcel.linkedGroupsGeometries': {
      handler() {
        this.renderLinkedGroup();
      }
    },
    'mapConfig.scale': {
      handler(value) {
        try {
          if (value) {
            this.map.addControls(['ScaleControl'], 'bottom-right');
          } else {
            this.map.removeControls(['ScaleControl']);
          }
        } catch {
          //
        }
      }
    },
    mapConfig: {
      handler() {
        this.renderParcel();
      },
      deep: true
    },
    parcelId: {
      handler() {
        const p = new Parcel({ id: this.parcelId });
        p.fetch().then(() => {
          this.data = p.get();
          this.loadNearbyParcels();
          this.init();
        });
      },
      immediate: true
    }
  },
  computed: {
    mapStyle: {
      get() {
        return this.mapConfig.style;
      },
      set(value) {
        this.$store.commit('setMapConfig', { key: 'style', value });
        this.map.setStyle(value);
      }
    },
    mapConfig() {
      return this.$store.state.parcel.mapConfig;
    },
    parcelFilters() {
      const opt = this.$store.state.parcel.mapConfig;
      const loggedInType = this.$store.state.loggedInAs?.type;
      const res = {
        acresFilter: opt.acres,
        referenceFilter: opt.reference,
        mapStyle: opt.style,
        idFilter: opt.showParcelId,
        preserve: opt.landPreserve,
        showPreserve: opt.showPreserve,
        monitoringColor: opt.monitoringColor,
        land: opt.land
      };
      if (loggedInType === 'user') {
        res.monitoringColor = false;
      }
      return res;
    },
    linkedGroups() {
      try {
        return this.$store.state.parcel.linkedGroups.filter(i => {
          if (i.admin && !this.$store.getters.isAdmin) {
            return false;
          }
          return !!i;
        });
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    linkedGroupsLabels() {
      return this.linkedGroups.map(i => i.value);
    }
  },
  beforeDestroy() {
    this.orientationObj.stop();
  },
  methods: {
    loadNearbyParcels() {
      getNearbyParcels([this.data]).then(res => {
        this.nearby.tct = res.tct || [];
        this.nearby.tot = res.tot || [];
        this.renderNearbyParcels(true);
      });
    },
    init() {
      setTimeout(() => {
        this.createMap();
      }, 100);
      const o = new Orientation();
      this.orientationObj = o;
      o.onChange(
        throttle(100, data => {
          if (Orientation.isValidOrientation(data?.heading)) {
            this.orientation = o.heading();
          }
        })
      );
    },
    onCopy() {
      this.snackbar.message = `Looking ${this.orientation} is confirmed`;
      this.snackbar.value = true;
      this.$store.commit('setOrientation', this.orientationObj.orientation);
      setTimeout(() => {
        this.snackbar.value = false;
      }, 3000);
    },
    onError() {
      this.snackbar.message = 'Something went wrong';
      this.snackbar.value = true;
      setTimeout(() => {
        this.snackbar.value = false;
      }, 3000);
    },
    isSatellite,
    renderParcel() {
      let geojson = parcelGeoJSON({ parcel: this.data, ...this.parcelFilters });
      this.map.add('parcel', 'GEOJSON', {
        geojson
      });
      this.map.enableHover('parcel');
      this.renderLinkedGroup();
      this.renderNearbyParcels(true);
    },
    async renderLinkedGroup() {
      const linkedGeometries = this.$store.state.parcel.linkedGroupsGeometries;
      const { linkedGroupGeometry, linkedGroupGeometryLabel } = this.$store.state.parcel.mapConfig;
      if (
        !linkedGeometries ||
        !Array.isArray(linkedGeometries) ||
        !linkedGroupGeometry ||
        !linkedGroupGeometryLabel
      ) {
        return;
      }

      const geojson = {
        type: 'FeatureCollection',
        features: []
      };
      let geostyles = [];

      linkedGeometries.forEach(geo => {
        const geometry = parseGeoJSON(geo.data.geojson);
        if (geometry) {
          const fmt = 'NNN-NNN';
          if (fmtParcelNumber(geo.data.parcel, fmt) === fmtParcelNumber(this.parcelNumber, fmt)) {
            geojson.features.push({
              geostyle: geo.data.geostyle,
              label: geo.data.label,
              geometry,
              group: geo.data.group
            });
            if (geo.data.geostyle) {
              geostyles.push(geo.data.geostyle);
            }
          }
        }
      });

      const cms = new CMS();
      geostyles = geostyles.map(i => cms.detail(null, i).catch(err => null));
      let results = await Promise.all(geostyles);
      results = results.filter(i => i);

      const getGeostyle = (items, key) => {
        return items.filter(i => i.data.key === key)[0];
      };

      geojson.features = geojson.features.map(feature => {
        const geostyleData = {
          ...(getGeostyle(results, feature.geostyle)?.data || {}),
          ...labelToGeostyle(feature.label)
        };

        if (isSatellite(this.style)) {
          geostyleData.textColor = 'white';
        }

        const group = LinkedGroup.group(feature.group);

        if (!(linkedGroupGeometry || []).includes(group)) {
          geostyleData.lineOpacity = 0;
          geostyleData.lineOpacitySatellite = 0;
          geostyleData.fillOpacity = 0;
          geostyleData.fillOpacitySatellite = 0;
        }
        if (!(linkedGroupGeometryLabel || []).includes(group)) {
          geostyleData.textOpacity = 0;
        }

        let res = Map.applyGeostyle(feature.geometry, geostyleData).features[0];
        res.properties.text = feature?.label?.text || getTextProperty(res.properties) || '';
        return res;
      });
      this.map.add('linked-parcels', 'GEOJSON', { geojson });
    },
    createMap() {
      if (!this.parcel) return;
      let geojson = parseGeoJSON(this.parcel);
      const ct = center(geojson).geometry.coordinates;

      const map = new Map({
        accessToken: config.mapboxToken,
        container: this.$refs.map,
        center: ct || [],
        zoom: 17,
        style: this.style
      });

      this.map = map;
      map.onLoad(() => {
        map.addControls(['GeolocateControl'], 'bottom-right');
        this.renderParcel();
        map.add('parcel-circle', 'GEOJSON', {
          geojson: Map.applyGeostyle(point(ct), geostyleParcelPoint),
          visible: false
        });
        map.onUpdateMap(
          throttle(1000, data => {
            if (Number(data.zoom) > 12) {
              map.hideLayer('parcel-circle');
            } else {
              map.showLayer('parcel-circle');
            }
          })
        );

        const fitParcelAndUser = () => {
          const gps = this.$store.state.gps;
          const padding = this.$vuetify.breakpoint.smAndUp ? 200 : 100;
          if (gps?.lng && gps?.lat) {
            const fcs = featureCollection([point([gps.lng, gps.lat]), point(ct)]);
            const boundary = bbox(combine(fcs));
            map.map.fitBounds(boundary, { padding, maxZoom: 16 });
          }
        };

        setTimeout(() => {
          map.controls.GeolocateControl.trigger();
        }, 200);
        setTimeout(() => {
          fitParcelAndUser();
        }, 1000);

        if (this.mapConfig.scale) {
          this.map.addControls(['ScaleControl'], 'bottom-right');
        }
      });
    },
    renderNearbyParcels(remove = false) {
      if (remove) {
        const tct = this.map.findSource('tct-parcels');
        if (tct.source) {
          tct.source.destroy();
        }
        const tot = this.map.findSource('tot-parcels');
        if (tot.source) {
          tot.source.destroy();
        }
      }

      const filters = {
        acresFilter: this.parcelFilters.acresFilter,
        referenceFilter: this.parcelFilters.referenceFilter,
        idFilter: this.parcelFilters.idFilter
      };

      const tct = this.map.findSource('tct-parcels');
      const tot = this.map.findSource('tot-parcels');

      if (tct.source) {
        if (this.mapConfig.tct) {
          tct.source.show();
        } else {
          tct.source.hide();
        }
      } else {
        const tctParcels = this.nearby.tct.map(p => {
          return parcelGeoJSON({ parcel: p, ...filters });
        });

        if (this.mapConfig.tct) {
          this.map.add('tct-parcels', 'GEOJSON', {
            geojson: {
              type: 'FeatureCollection',
              features: tctParcels,
              visible: this.mapConfig.tct
            }
          });
        }
      }

      if (tot.source) {
        if (this.mapConfig.tot) {
          tot.source.show();
        } else {
          tot.source.hide();
        }
      } else {
        const totParcels = this.nearby.tot.map(p => {
          return parcelGeoJSON({ parcel: p, ...filters });
        });

        if (this.mapConfig.tot) {
          this.map.add('tot-parcels', 'GEOJSON', {
            geojson: {
              type: 'FeatureCollection',
              features: totParcels
            }
          });
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.map {
  height: 100%;
  width: 100%;
  position: relative;

  .orientation {
    position: absolute;
    width: 100%;
    z-index: 1;
  }
}
</style>
